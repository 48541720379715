body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


.css-1qhov81 {
  font-family: Helvetica, sans-serif;
  font-size: 13px;
  border-radius: 0px 0px 5px 5px;
}

.css-1qhov81 .rw-container-main {
  color: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
  background: linear-gradient(to right bottom, rgb(1, 129, 194), rgb(4, 167, 249), rgb(75, 196, 247));
  display: flex;
  border-radius: 5px 5px 0px 0px;
}

.css-1qhov81 .rw-container-header {
  margin: 0 0 10px;
  font-weight: 300;
  font-size: x-large;
  letter-spacing: 2px;
}

.css-1qhov81 .rw-container-left {
  padding: 25px;
  width: 60%;
}

.css-1qhov81 .rw-container-right {
  background-color: rgba(0, 0, 0, 0.1);
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-w3t7ie .rw-today-date {
  color: rgb(181, 222, 244);

  font-size: 1rem;
  margin: 0 0 5px 2px;

  font-weight: bold;
}

.css-w3t7ie .rw-today-current {
  font-size: 45px;
  color: rgb(255, 255, 255);
}

.css-w3t7ie .rw-today-range {
  color: rgb(181, 222, 244);
  font-size: 12px;
  margin: 0 0 5px 2px;
}

.css-w3t7ie .rw-today-desc {
  color: rgb(181, 222, 244);
  font-size: 16px;
}

.css-w3t7ie .rw-today-info {
  color: rgb(181, 222, 244);
}

.css-w3t7ie .rw-today-info div {
  margin-bottom: 5px;
}

.css-w3t7ie .rw-today-hr {
  width: 100%;
  height: 0;
  border-bottom: 1px solid rgb(255, 255, 255);
  opacity: 0.4;
  margin: 10px 0;
}

.css-1gnycvt {
  fill: rgb(255, 255, 255);
}

.css-evb4g3 {
  clear: both;
  display: flex;
  border-left: 1px solid rgb(255, 255, 255);
  border-right: 1px solid rgb(255, 255, 255);
  border-bottom: 1px solid rgb(255, 255, 255);
  border-radius: 0px 0px 5px 5px;
  font-size: 11px;
  background-color: rgb(255, 255, 255);
}

.css-evb4g3 .rw-forecast-day {
  width: 25%;
  text-align: center;
  margin: 10px 0;
  padding: 0 10px;
}

.css-evb4g3 .rw-forecast-date {
  color: rgb(119, 119, 119);
  font-size: 11px;
  font-weight: bold;
}

.css-evb4g3 .rw-forecast-desc {
  color: rgb(119, 119, 119);
  margin: 10px 0;
  font-size: 12px;
}

.css-evb4g3 .rw-forecast-range {
  color: rgb(119, 119, 119);
  font-size: 11px;
}

.css-evb4g3 .rw-forecast-icon {
  padding-top: 10px;
}

.css-w933ne {
  fill: rgb(75, 196, 247);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: 20s linear 0s infinite normal none running App-logo-spin;
  }
}

.App-header {
  background-color: rgb(40, 44, 52);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: rgb(97, 218, 251);
}

@keyframes App-logo-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.rw-forecast-icon img {
  max-width: 100%;
  max-height: 100%;
  width: auto; /* Ensures the image scales proportionally */
  height: auto; /* Ensures the image scales proportionally */
}

.rw-container-main {
  max-width: 100%;
  max-height: 100%;
  width: auto; /* Ensures the image scales proportionally */
  height: auto; /* Ensures the image scales proportionally */
}